<template>
  <div>
      <div class="line-til">您不满意的主要原因是什么？</div>
      <van-field v-model="result2" :rules="[{ required: true, message:`请选择对${vdata.title}不满意的原因` }]" style="display: none;"></van-field>
      <van-checkbox-group v-model="result" @change="onChange()">
        <div v-for="(option,j) in extralist" :key="j" class="line-opt">
          <van-checkbox shape="square" :name="`${index}_3_${j}`">{{option.label}} </van-checkbox>
          <div v-if="option.selected">
            <van-field
                v-model="option.desc"
                rows="2"
                :name="`${index}_${j}_extra`"
                type="textarea"
                maxlength="100"
                show-word-limit
                placeholder="请具体描述"
                @update:model-value="(val)=>{updatedesc(j,val)}"
                :rules="[{ required: option.extra, message:`请具体描述对${vdata.title}不满意的其他原因` }]"
            />
          </div>
        </div>

      </van-checkbox-group>
  </div>
</template>

<script>
import {reactive, toRefs} from "vue";

export default {
  name: "MultiQuestion",
  props:{
    index:Number,
    vdata:Object,
    status:Number//0 未参加 1正在参加 2 已参加
  },
  setup(props,{emit}){

    const state = reactive({
      result:[],
      result2:'',
      desc:'',
      extralist: [],
      onChange:function (){
        if(state.result.length>0){
          state.result2 = 'ok';
        }else{
          state.result2 = '';
        }
       // console.log(state.result)
        state.extralist.forEach(item=>{
          item.selected = false;
        })
        state.result.forEach(item=>{
          let i = item.split('_')[2];
          state.extralist[i].selected = true;
        })
        state.formate();
      },
      updatedesc:function (i,val){
        state.extralist[i].desc = val;
        state.formate();
      },
      formate:function (){
        let _brr = [];
        state.extralist.forEach(item=>{
          if(item.selected){
            _brr.push({
              casz:item.label,
              desc:item.desc
            })
          }
        })
        emit('answer',[props.index,_brr]);
      }
    })
    state.extralist = JSON.parse(JSON.stringify(props.vdata.extralist));
    return {
      ...toRefs(state),
    };
  }
}
</script>

<style scoped>
.line-til{margin: 10px 0;}
.line-opt{padding:5px 0;}
.analysis{margin-bottom: 10px;}
.ques-img img{display: block;width: 100%;padding: 0 0 10px 0;}
</style>