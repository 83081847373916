import { createApp } from 'vue'
import App from './App.vue'
import {Field,CellGroup,Cell,Picker,Form,Popup,Toast,Button,Checkbox,Radio,RadioGroup,CheckboxGroup} from "vant"
const app = createApp(App);
      app.use(Field);
      app.use(CellGroup);
      app.use(Cell);
      app.use(Picker);
      app.use(Form);
      app.use(Popup);
      app.use(Toast);
      app.use(Button);
      app.use(Checkbox);
      app.use(Radio);
      app.use(RadioGroup);
      app.use(CheckboxGroup);
      app.mount('#app')
