<template>
  <div>
      <div class="line-til">{{list[index]}}、您对{{vdata.extendtitle}}的评价</div>
      <van-field v-model="result" :rules="[{ required: true, message:`请完成第${index+1}个选项` }]" style="display: none;"></van-field>
      <van-radio-group v-model="result" @change="$emit('answer',[index,vdata.title,result])">
        <div v-for="(option,j) in vdata.option" :key="`1_${j}`" class="line-opt">
          <van-radio :name="`${index}_${j}`">{{option}} </van-radio>
        </div>
      </van-radio-group>

  </div>
</template>

<script>
import {reactive, toRefs} from "vue";
//import MultiQuestion from "@/components/MultiQuestion";
export default {
  name: "SingleQuestion",
  props:{
    index:Number,
    vdata:Object,
  },
  setup(){
    const state = reactive({
      result:'',
      list:['一','二','三','四','五','六'],
    })

    return {
      ...toRefs(state),
    };
  }
}
</script>

<style scoped>
.line-til{margin: 10px 0;font-size: 16px;font-weight: bold;}
.line-opt{padding:5px 0;font-size: 16px;}
</style>