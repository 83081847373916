<template>
  <div class="box">
    <div class="el-form">
      <van-form @submit="onSubmit" @failed="safeError" :show-error-message="false" :scroll-to-error="true">
        <div v-for="(item,i) in list" :key="i" >
          <single-question :vdata="item" :index="i" @answer="singleAnswer"></single-question>
          <div v-if="item.feedback=='3'">
            <multi-question :vdata="item" :index="i" @answer="extraAnswer"></multi-question>
          </div>
        </div>
        <div class="part2">
          <ComPicker :list="area" :call-back='confirm' title="所属街镇" id="1"></ComPicker>
          <van-field
              v-model="username"
              name="username"
              placeholder="请输入您的姓名"
              autocomplete="off"
              :rules="[{ required: true, message: '请输入您的姓名' }]"
          >
            <template #label>
              <div class="field-label">姓名<span class="star">*</span></div>
            </template>
          </van-field>
          <van-field
              v-model="phone"
              name="phone"
              label="电话"
              type="number"
              maxlength="11"
              autocomplete="off"
              placeholder="请输入您的手机号"
              :rules="[{ required: true, message: '请输入11位电话号码' ,pattern:pattern5}]"
          >
            <template #label>
              <div class="field-label">电话<span class="star">*</span></div>
            </template>
          </van-field>
        </div>

        <div class="btn">
          <van-button round block type="primary" native-type="submit">提交</van-button>
        </div>
      </van-form>

    </div>
  </div>
  <div class="botm"></div>
</template>

<script>

import {toRefs,reactive,onMounted} from "vue";
import { Toast } from 'vant';
import ComPicker from "@/components/ComPicker";
import SingleQuestion from "@/components/SingleQuestion";
import MultiQuestion from "@/components/MultiQuestion";

import { arealist, questionlist} from "@/feedlist"
import 'vant/lib/index.css';
import axios from "axios";
export default {
  name: 'App',
  components: {
    ComPicker,
    SingleQuestion,
    MultiQuestion
  },
  setup(){
    const state = reactive({
      satislist:['','满意','基本满意','不满意','不了解'],
      next:true,
      list:[],
      area:[],
      region:'',
      content:'',
      username:'',
      phone:'',
      type:'',
      pattern5:/^\d{11}$/,
      ifpicker:false,
      result: {},
      onSubmit:async ()=>{
        Toast('数据提交中');
        let {data} = await state.create();
        if(data.code == 0){
          Toast({'message':'提交成功',
          onClose:()=>{
            location.reload();
          }});

        }else{
          Toast(data.msg);
        }

      },
      create:()=>{
        state.result.length = 6;
        let params = {
          'username':state.username,
          'userphone':state.phone,
          'region':state.region,
        }
        params.questionnaires = Array.from(state.result);
        return axios.post('/h5/feedback/create',params);
      },
      safeError:function (val){
        Toast(val.errors[0].message);
      },
      singleAnswer:function (value){
        let satis = value[2].split('_');
        state.result[value[0]] = {
          'type':value[1],
          'satisficing':state.satislist[satis[1]]
        }
        state.list[satis[0]].feedback = satis[1];
      },
      extraAnswer:function (value){
        state.result[value[0]].cases = value[1];
        console.log('选值:',JSON.parse(JSON.stringify(state.result)))
      },
      confirm:function (val) {
        state.ifpicker = false;
        state.region = val.text;
      },
    });
    axios.defaults.baseURL = process.env.NODE_ENV == 'development' ? 'https://api.safer.yooar.com' : 'https://feedback.yooar.com/h5api';
    onMounted(()=>{
      state.area = arealist();
      state.list = questionlist();
      state.list.forEach(item=>{
        item.feedback = '';
        item.extralist = [];
        Object.keys(item.extra).forEach(key=>{
          let i = ((item.extraIndex+'') == key) ? true : false;
          item.extralist.push({
            'label':item.extra[key],
            'desc':'',
            'selected':false,
            'extra':i
          })
        });
      })
    })
    return {
      ...toRefs(state)
    }
  }
}
</script>

<style>
body{
  margin: 0;
  padding: 0;
  background: url("./assets/tower.jpg");
  background-repeat: no-repeat;
  background-size: 100% auto;
  position: relative;
  background-color: #85c8ff;
}
.star{color:red;}
.btn{width: 50%;margin: 30px auto;}
.field-label{font-size: 16px;color:#333;}
.box{
  box-sizing: border-box;
  border-radius:15px;
  background: #fff;
  margin:248px auto 15px;
  width: 326px;
  padding:15px;
}
input,textarea{font-size: 16px;}
textarea{height: 50px;}
.van-cell{border-bottom:1px solid #ccc;padding:10px 0;}
.botm{height: 30px;}
.part2{margin-top: 15px;}
</style>
