<template>
  <div>
    <van-field
        v-model="label"
        is-link
        readonly
        name="label"
        placeholder="请选择"
        @click="openPicker"
        :rules="[{required: true,message: getMes }]"
    >
      <template #label>
        <div class="field-label">{{ title }}<span class="star">*</span></div>
      </template>
    </van-field>
    <van-popup v-model:show="ifpicker" position="bottom">
      <van-picker
          :columns="list"
          @confirm="confirm"
          @cancel="ifpicker = false"

      />
    </van-popup>
  </div>
</template>

<script>
import {reactive, toRefs} from 'vue';

export default {
  name: 'ComPicker',
  props: {
    list:Array,//弹层列表内容
    title:String,//标题内容
    callBack:Function,//父级回调
    id:String
  },
  components: {
  },
  setup(props) {
    const state = reactive({
      ifpicker:false,
      label:'',//显示项
      getMes:function (){
        return '请选择'+props.title;
      },
      openPicker:function (){
        state.ifpicker = true;
      },
      confirm:function (value) {
        state.ifpicker = false;
        state.label = value.text;
        props.callBack(value,props.id);
      }
    })

    return {
      ...toRefs(state)
     };
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.close{
  display:inline-block;width: 20px;height: 20px;background: red;color: #fff;font-size: 12px;font-weight: bold;
}

</style>
