export const arealist = function (){
    return [
        {"text":"锦秋街道"},
        {"text":"博昌街道"},
        {"text":"城东街道"},
        {"text":"陈户镇"},
        {"text":"纯化镇"},
        {"text":"乔庄镇"},
        {"text":"湖滨镇"},
        {"text":"曹王镇"},
        {"text":"兴福镇"},
        {"text":"庞家镇"},
        {"text":"店子镇"},
        {"text":"吕艺镇"}
    ]
}
export const questionlist = function(){
    return [
        {
            title:'居住环境',
            extendtitle:'当地居住环境和生活环境',
            option:{1:'满意',2:'基本满意',3:'不满意',4:'不了解'},
            extra:{
                '1':'道路硬化不好',
                '2':'基础设施建筑影响出行',
                '3':'公共交通不便利',
                '4':'绿化、养护不到位',
                '5':'公共服务设施不足、维修服务不及时',
                '6':'生活垃圾处理不及时',
                '7':'空气质量差',
                '8':'其他（请具体描述）',
            },
            extraIndex:8
        },
        {
            title:'社会治安',
            extendtitle:'当地社会治安状况',
            option:{1:'满意',2:'基本满意',3:'不满意',4:'不了解'},
            extra:{
                '1':'盗窃、抢劫、拐骗时有发生 ',
                '2':'打架斗殴、寻衅滋事经常遇见',
                '3':'卖淫嫖娼、聚众赌博、吸毒贩毒屡禁不止',
                '4':'网络诈骗、网络传销防不胜防',
                '5':'非法集资偶尔发生',
                '6':'流氓黑恶团伙依然存在',
                '7':'执法不规范、窗口服务态度差',
                '8':'其他（请具体描述）',
            },
            extraIndex:8
        },
        {
            title:'基本医疗',
            extendtitle:'当地基本医疗',
            option:{1:'满意',2:'基本满意',3:'不满意',4:'不了解'},
            extra:{
                '1':'看病就医距离远不方便',
                '2':'看病排队时间长',
                '3':'医务人员服务态度不好',
                '4':'医疗水平不高，过度依赖检查 ',
                '5':'医疗费用贵',
                '6':'报销手续复杂',
                '7':'报销不及时',
                '8':'其他（请注明）',
            },
            extraIndex:8
        },
        {
            title:'文体活动',
            extendtitle:'当地的文化娱乐和体育健身',
            option:{1:'满意',2:'基本满意',3:'不满意',4:'不了解'},
            extra:{
                '1':'免费开放的文体活动场所少',
                '2':'组织文体活动少',
                '3':'公共体育健身器材更新维护不及时',
                '4':'其他（请具体描述）',
            },
            extraIndex:4
        },
        {
            title:'基础教育',
            extendtitle:'当地的基础教育',
            option:{1:'满意',2:'基本满意',3:'不满意',4:'不了解'},
            extra:{
                '1':'公立幼儿园少',
                '2':'幼儿园收费高',
                '3':'片区划分不合理',
                '4':'就近入学、转学难',
                '5':'教学质量不高',
                '6':'学生作业多',
                '7':'家长批改检查作业压力大',
                '8':'课外辅导收费高',
                '9':'需家长参与的活动多',
                '10':'其他（请注明）',
            },
            extraIndex:10
        },
        {
            title:'社会帮扶',
            extendtitle:'当地社会帮扶情况',
            option:{1:'满意',2:'基本满意',3:'不满意',4:'不了解'},
            extra:{
                '1':'养老机构少，收费高',
                '2':'帮扶政策不了解 ',
                '3':'帮扶救助申请难',
                '4':'困难人员得不到及时救助',
                '5':'对老弱病残提供的社会福利分配不公',
                '6':'其他（请注明）'
            },
            extraIndex:6
        }
    ];
}
